// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url: 'https://back-accon.herokuapp.com',
  firebase: {
    apiKey: "AIzaSyBFxwhn3rq200-MXwkDrLTdWO0yAQU57pQ",
    authDomain: "accon-demo-frontend.firebaseapp.com",
    projectId: "accon-demo-frontend",
    storageBucket: "accon-demo-frontend.appspot.com",
    messagingSenderId: "1098922948287",
    appId: "1:1098922948287:web:144a00b3ae568dabdd780a"
  },
  onesignal: {
    URL:'https://onesignal.com/api/v1/notifications',
    Autorizacion: 'Basic ZmJhODFkNjEtMGNkNi00YjQ1LTlkNGYtZjExNDMwM2I4OWZl',
    AppID: '1c75205b-91c6-4ed2-b5a3-aa0b61dff5c9'
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
